<template>
  <div>
    <h3>25 June 2010 (0.186.5)</h3>
    <p>
      <u>Model Mode</u>
      <br>- Neither the game camera nor your Minifigure will get stuck underground or up high after putting down a model.
    </p>
    <p>
      <u>Avant Gardens</u>
      <br>- Using the launch pad to Nimbus Station will no longer cause you to slide backwards.
    </p>
    <p>
      <u>Nimbus Station Race Track</u>
      <br>- The “Wrong Way” spawnpoints now work correctly.
    </p>
    <p>
      <u>Gnarled Forest</u>
      <br>- The fall camera in the ravine now works. Character Creation
      <br>- There is now a new set of namenator names to choose from.
    </p>
    <h3>24 June 2010 (0.186.4) </h3>
    <p>
      <u>Pets</u>
      <br>- Using a space in your pet name will no longer generate an error message.
    </p>
    <p>
      <u>Passport</u>
      <br>- LEGO Score will now track correctly.
      <br>- Player stats are now accurate.
    </p>
    <p>
      <u>User Interface</u>
      <br>- The Orange Barricade model will now have the correct icon in the backpack and the achievement pop-up.
      <br>- You will now see minimaps and world maps for LUP Station and Club LEGO zones.
      <br>- Opening up the News Screen will stun your Minifigure.
    </p>
    <h3>23 June 2010 (0.186.3)</h3>
    <p>
      <u>Character Creation</u>
      <br>- The character delete password confirmation box is now case-sensitive.
    </p>
    <p>
      <u>User Interface</u>
      <br>- The stack splitter will now display when buying, selling, or trading stacks of items.
    </p>
    <p>
      <u>Pets</u>
      <br>- Pet naming entry boxes are limited to 24 characters.
    </p>
    <p>
      <u>Gnarled Forest</u>
      <br>- The showcases have been removed.
      <br>- The Medium Property launchpad has been removed.
      <br>- You can now run across the Tortoise Terrace bouncer to jump.
    </p>
    <p>
      <u>Avant Gardens</u>
      <br>- The showcases have been removed.
      <br>- The Medium Property launchpad has been removed.
    </p>
    <p>
      <u>Nimbus Station</u>
      <br>- The showcases have been removed.
      <br>- The Medium Property launchpad has been removed.
    </p>
    <h3>22 June 2010 (0.186.2)</h3>
    <p>
      <u>Avant Gardens</u>
      <br>- The triceratops should now be tamable.
    </p>
    <p>
      <u>Nimbus Station</u>
      <br>- The Maelstrom Console now works correctly.
      <br>- The race track loads much faster.
    </p>
    <p>
      <u>Gnarled Forest</u>
      <br>- The invisible object in the Elephant Encampment is gone.
      <br>- The race track loads much faster.
    </p>
    <p>
      <u>Pets</u>
      <br>- The Pet Paw Icon now appears after taming a pet.
    </p>
    <h3>16 June 2010 (0.186.0)</h3>
    <p>
      <u>News Screen</u>
      <br>- Check out the new News Screen! The first time you log into a character in one play session, the News Screen will pop up, featuring news, tips, the 3 achievements you are closest to completing and much more.
    </p>
    <p>
      <u>Racing</u>
      <br>- The leaderboard has been updated.
      <br>- The Skull on the Gnarled Forest Race Track now has flames in front of it, so be careful.
      <br>- A finish line cam has been installed at the Nimbus Station Race Track.
    </p>
    <p>
      <u>Currency</u>
      <br>- The “Smash Penalty” now removes a greater amount of coins.
      <br>- The cost of Imagination, life, and armor consumables has been decreased.
    </p>
    <p>
      <u>Faction Kit News</u>
      <br>- There is now a Clod Chucking Shovel, a Cauldron of Life, an Anvil of Armor, and a Spring of Imagination!
    </p>
    <p>
      <u>Backpack</u>
      <br>- It is now possible to drag items into the consumable slot on the paperdoll.
      <br>- You will get a pop up if you try to open any packages that cannot fully unpack into the inventory.
    </p>
    <p>
      <u>Passport</u>
      <br>- New rollover descriptions have been added for the achievements.
      <br>- The Avant Gardens Pet Tamer achievement should now show the correct task total.
      <br>- The mission progress percentage now displays correctly.
      <br>- Once your custom name is approved by moderation, it will update dynamically in the Passport.
    </p>
    <p>
      <u>Trade</u>
      <br>- It is now possible to buy, sell, or trade more than one item in a stack by right-clicking the item.
    </p>
    <p>
      <u>Forbidden Valley</u>
      <br>- Candles now react to water guns.
      <br>- Check out the Kung Fu gis and the attack skills it gives you.
      <br>- Maelstrom Cavalry, Ronin and Dragons now smash into the proper bricks.
      <br>- Numb Chuck’s “Smash Dragon” mission now rewards the Dragon Tooth.
    </p>
    <p>
      <u>Gnarled Forest</u>
      <br>- Torches now react to water guns.
      <br>- Also, try spraying water on the campfires.
      <br>- You no longer have to walk from the cannon by the organ to interact with it again. Nimbus Station
    </p>
    <p>
      <u>Racing</u>
      <br>- The race track now has banner effects.
      <br>- The game will no longer freeze, when you exit a Choice Build.
      <br>- New fantastic visual effects have been added to the race track.
    </p>
    <p>
      <u>Avant Gardens</u>
      <br>- No more floating trees!
      <br>- In Survival Instances, you can no longer equip and unequip items while they are smashed.
    </p>
    <p>
      <u>Pets</u>
      <br>- After successfully completing a pet taming mingame, the animation of your Minifigure building the model will be glitch free.
      <br>- Players wearing armor will now be kicked from the pet taming minigame, if they are attacked by an enemy.
      <br>- Pet taming minigame “speed taming” achievements now count the end time as the moment the model is done building.
      <br>- Pets should no longer stay in the same position as you and “go wild”.
      <br>- The pet command menu goes away when the pet performs the command.
    </p>
    <p>
      <u>User Interface</u>
      <br>- You can now click “Report Offensive” on the main help screen or select the “Report Abuse” option on the help request screen to report a player or a user-generated model as offensive.
      <br>- The Emote Button is now placed below chat.
      <br>- Clicking names/heads in chat will activate the drop-down menu.
      <br>- Tooltips have been added to the open dock icons.
      <br>- Multiple opened windows will now close in reverse-opened order when using the escape key.
    </p>
    <p>
      <u>Database</u>
      <br>- 58 new reward models have been included! We proudly present the Country Cottage Building Set (complete with kitchen, living room, and library embellishments), the Country Farm Building Set (with barn, silos, tractor, and fencing) and the Ancient Buildings Building Set (including everything you need to make your own Greek and Roman Ruins).
    </p>
    <h3>12 June 2010 (0.185.20) </h3>
    <p>
      - Fixed issue where you were frequently kicked out of the game.
    </p>
    <h3>11 June 2010 (0.185.19) </h3>
    <p>
      <u>Pets</u>
      <br>- Pets can now be named when entering a name after taming your pet.
      <br>- Issues with pet taming have now been fixed.
    </p>
    <h3>11 June 2010 (0.185.18) </h3>
    <p>
      <u>Character Create</u>
      <br>- When creating new characters, you should no longer get stuck at the character name step.
    </p>
    <p>
      <u>Pets</u>
      <br>- When pets idle, you will no longer get sent back to the log in screen.
    </p>
    <p>
      <u>Racing</u>
      <br>- The starting line is now staggered 3-3, not 2-2-2. Survival
      <br>- You can only hit the Start button once.
    </p>
    <h3>10 June 2010 (0.185.16) </h3>
    <p>
      <u>Brick Mode</u>
      <br>- Placing either the Car Chassis or Steel Rim Wheels modules in Brick Mode will not cause the game to crash.
    </p>
    <p>
      <u>Exit Page</u>
      <br>- The option to hide the Game Exit page will no longer appear.
    </p>
    <h3>9 June 2010 (0.185.15) </h3>
    <p>
      <u>Survival</u>
      <br>- Clicking the "exit" button after completing a Survival match takes you back to Avant Gardens.
    </p>
    <p>
      <u>Chat</u>
      <br>- The Speedchat menu now appears correctly.
    </p>
    <p>
      <u>Racing</u>
      <br>- The boost pads at the Gnarled Forest race track now produces a more consistent boost effect.
    </p>
    <p>
      <u>Loading Times</u>
      <br>- The loading time when changing maps should now be of an acceptable duration.
      <br>- The Character Select screen after selecting "Switch Minifigure" now loads faster.
    </p>
    <p>
      <u>Switching Minifigure</u>
      <br>- Progress is saved when you switch between Minifigures.
    </p>
    <h3>8 June 2010 (0.185.14) </h3>
    <p>
      <u>Property</u>
      <br>- Unmoderated properties now display the temporary name "awaiting moderation" in the property navigator.
      <br>- Models with many unattached bricks no longer cause properties to take a long time to load.
    </p>
    <p>
      <u>Racing</u>
      <br>- Cars can stop at any point along a vertical section of track and then resume driving.
      <br>- Cars will no longer repeatedly boost and unboost when on boost pads in the Nimbus Station track.
    </p>
    <p>
      <u>Factions</u>
      <br>- On mouse over, faction weapons display their damage information correctly.
    </p>
    <p>
      <u>Help Menu</u>
      <br>- The "Report Offensive" button displays the correct window.
      <br>- All available buttons now display properly.
    </p>
    <p>
      <u>Moderation</u>
      <br>- Models are now being sent to the moderation tool.
      <br>- Property is now sent to the moderation tool.
    </p>
    <h3>7 June 2010 (0.185.13) </h3>
    <p>
      <u>Minifigure Creation</u>
      <br>- You are now able to create new Minifigures again.
    </p>
    <p>
      <u>Chat</u>
      <br>- Selecting the “Report Offensive” button after interacting with another tester will now automatically select the appropriate item in the list on the help menu.
    </p>
    <p>
      <u>Racing</u>
      <br>- You no longer fall out of the world when running off ramps at an angle.
    </p>
    <p>
      <u>Mail</u>
      <br>- Attempting to send mail will no longer cause the graphics to freeze.
    </p>
    <p>
      <u>Passport</u>
      <br>- The Rank 3 Buccaneer achievement will now be awarded properly, allowing you to use the Rank 3 Buccaneer gear.
    </p>
    <h3>4 June 2010 (0.185.12) </h3>
    <p>
      <u>Factions</u>
      <br>- Purchasing Rank 2 and Rank 3 equipment will now take the proper amount of coins.
    </p>
    <p>
      <u>Racing</u>
      <br>- Tab key functionality has been removed.
      <br>- After clicking through the finish screen, there should no longer be a blank screen.
    </p>
    <p>
      <u>Property</u>
      <br>- Using the “Report this Property” button at a property plaque will no longer cause you to become stuck.
    </p>
    <p>
      <u>Mail</u>
      <br>- You are now able to send mail again.
    </p>
    <p>
      <u>Game launcher</u>
      <br>- The game launcher will automatically update your shortcuts.
    </p>
    <h3>3 June 2010 (0.185.11) </h3>
    <p>
      <u>Venture Explorer</u>
      <br>- Boxes have been added to all the double-jump steps leading up to the central platform.
      <br>- There is a QuickBuild bouncer that can send you to the top of the platform from the launchpad side.
    </p>
    <p>
      <u>Forbidden Valley</u>
      <br>- The elevator leading to Numb Chuck is now working correctly.
    </p>
    <p>
      <u>Mail</u>
      <br>- Attempting to send mail with a model you have created attached is now working properly.
    </p>
    <p>
      <u>Chat</u>
      <br>- The Speedchat menu is now more easy to read.
    </p>
    <p>
      <u>Brick and Model Inventory</u>
      <br>- Deleting a rocket or a racecar from the backpack gives you bricks, just like other reward models.
    </p>
    <p>
      <u>Faction Kits</u>
      <br>- If you attempt to pick a class and there is not enough space for all of the kit items, then instead of receiving only some of the items, you will receive a prompt to free up the needed amount of inventory slots.
      <br>- Faction weapons should now have their correct description in the vendor window.
    </p>
    <p>
      <u>Racing</u>
      <br>- A race only begins with a minimum of three racers. You cannot enter a race alone.
    </p>
    <h3>2 June 2010 (0.185.10) </h3>
    <p>
      <u>User Interface</u>
      <br>- Popup tutorials will now recognize if you are using right or left handed mouse configuration. Bricks
      <br>- Deleting a reward model in the backpack will now award bricks from that model.
    </p>
    <p>
      <u>Properties</u>
      <br>- You should be able to visit other players’ moderated properties.
    </p>
    <p>
      <u>Venture Explorer</u>
      <br>- Boxes have been placed at the double-jump steps to allow testers who are using computers with minimum specifications to get up the stairs.
    </p>
    <p>
      <u>Gnarled Forest</u>
      <br>- You can now interact with the tiki torches multiple times.
    </p>
    <h3>2 June 2010 (0.185.9) </h3>
    <p>
      <u>User Interface</u>
      <br>- The drop down menu no longer has the Best Friends button greyed out.
      <br>- You should now again be able to create new Minifigures without problems.
    </p>
  </div>
</template>
